.toggleCheckbox {
    display: none;
  }
//1976d2
  .toggleContainer {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: fit-content;
    border: 1px solid silver;
    border-radius: 10px;
    background: white;
  
    color: #1976d2;
    cursor: pointer;
    font-size: 0.75rem;
    font-family: "Montserrat";
    font-weight: 500;
 }

 .toggleContainer::before {
  content: '';
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0%;
  border-radius: 10px;
  background: #1976d2;
  transition: all 0.3s;
}

.toggleContainer div {
  padding: 0px 10px;
  text-align: center;
  z-index: 1;
}

.toggleCheckbox:checked + .toggleContainer::before {
  left: 50%;
}

.toggleCheckbox:checked + .toggleContainer div:first-child{
  color: #000;
  transition: color 0.3s;
}
.toggleCheckbox:checked + .toggleContainer div:last-child{
  color: white;
  transition: color 0.3s;
}
.toggleCheckbox + .toggleContainer div:first-child{
  color: white;
  transition: color 0.3s;
}
.toggleCheckbox + .toggleContainer div:last-child{
  color: #000;
  transition: color 0.3s;
}