.le_weekly_row div{
    padding: 8px 0;
}

.le_weekly_row.center{
    text-align: center;
}
.le_weekly_row .MuiDataGrid-cellContent{
    width: 100%;
}

.le_weekly_row.right{
    text-align: right;
}

.le_weekly_row.no_wrap{
    white-space: nowrap;
    overflow: hidden;
}

.le_weekly_row.no_wrap:focus-within {
    outline: none !important;
}

.le_weekly_row.first_pinned_row{
    box-shadow: -2px 0px 4px -2px rgba(0, 0, 0, 0.21);
    border-right: 1px solid #e0e0e0;
}

.le_weekly .first_pinned_header{
    background: #f1f1f1;
    border-right: 1px solid #e0e0e0;
    box-shadow: 0px -3px 1px -2px rgba(0, 0, 0, 0.2), 
            0px -2px 2px 0px rgba(0, 0, 0, 0.14), 
            0px -1px 5px 0px rgba(0, 0, 0, 0.12);


}

.le_weekly_row.last_pinned_row{
    border-left: 1px solid #e0e0e0;
}
.le_weekly .last_pinned_header{
    border-left: 1px solid #e0e0e0;
}