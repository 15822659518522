@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;1,500&display=swap');

@font-face {
 font-family: "Arial Regular";
 src: url("../../../assets/fonts/Arial/arial.woff2") format("woff2"),
      url("../../../assets/fonts/Arial/arial.woff") format("woff"),
}

@import url('../../Reconciliation/style.scss');
@import url('../../../components/DialogStatus/style.scss');
@import url('../../../components/CustomActionTable/style.scss');
@import url('../../../components/MenuGroup/style.scss');
@import url('../../../components/CustomToolbar/style.scss');
@import url('../../../components/LoggedIn/style.scss');
@import url('../../../components/SubPage/style.scss');
@import url('../../AOHeaderDetail/style.scss');
@import url('../../ActualDataInternal/style.scss');
@import url('../../Model/style.scss');
@import url('../../Home/style.scss');
@import url('../../Configuration/style.scss');
@import url('../../../components/Modals/style.scss');
@import url('../../AccrualPostingList/style.scss');
@import url('../../../components/CustomToolbarHome/style.scss');
@import url('../../Migration/style.scss');
@import url('./media.scss');
@import url('../../../components/Header/style.scss');
@import url('../../../components/DarkMode/style.scss');
@import url('../../../components/HelpComponent/style.scss');
@import url('../../TradeExpeditiureMonitor/style.scss');
@import url('../../AccrualAdjustments/style.scss');
@import url('../../../components/Switch/style.scss');
@import url('../../LatestEstimateData/style.scss');

.delete-style-button{
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.bpx-temporary-fontSize input, .bpx-temporary-fontSize label{
  font-size: 12px !important;
}


.subtotal-filter-block .subtotal-filter-field .Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23);
}

.dialog-fonts {
  font-family: "Arial Regular";

    h2 {
      font-family: "Arial Regular";
    }
}
.modal-circle-loading {
  text-align: center;
  position: relative;
  width: 60px;
  height: 60px;
  margin: auto;
}   
.modal-circle-number {
  position: absolute;
  height: 24px;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 17px;
  font-weight: bold;
}

.modal-circle-text {
  text-align: center;
  margin-top: 1rem;
}


.block-look-feel {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}
.section-look-feel {
  width: 60%;
}
.section-look-feel.second {
  width: 40%;
}
.section-look-feel.second label {
  margin-left: 0;
  margin-top: 0.3rem;
}

.close-for-modal-window {
  position: absolute;
  right: 17px;
  top: 17px;

    &.position-smaller {
      top: 5px;
      right: 5px;  
    } 
    &.cancel-delete-config {
      top: -7px;
      right: 5px;
    }
    &.cancel-model-modal {
      top: 2px;
      right: 2px;
    }
    .wrap-cancel-modal {
      padding: 5px;
    }
    
}

.bpx-reference-keys .bpx-reference-text {
  margin-top: 0.3rem;
}

.notify-cancel-modal {
  padding: 4px !important;

    svg {
      color: #fff;
      font-size: 1.3rem;
    }
}

.customOptionTableSpan {

   a {
    padding: 6px 16px 6px 0;
    display: block;
   }

}
.bpx-select-item.customOptionTableSpan {
  padding: 6px 16px 6px 0;
  display: block;
} 
.bpx-select-item.customOptionStatus {
  text-decoration: none;
  color: #000;
  white-space: pre-line;
}

.MuiDataGrid-root .wrapCell.MuiDataGrid-cell  {
  word-break: break-word;
  white-space: break-spaces;// break-all;
  overflow-wrap: break-word;//break-all ;
  text-align: right;
}

.MuiDataGrid-root .wrap-header {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.MuiDataGrid-main .m{
  font-size: 2rem;
}

.dialog-fonts-title {
  border-bottom: 2px solid black;
  padding-bottom: 0.5rem;
  text-align: center;
}

.dialog-loading-text {
  font-size: 1.2rem;

  div:last-child {
    margin-top: 1rem;
  }
  
}
.bpx-login-page-select-bu .MuiSelect-select:focus{
  background: none !important;
}

